<template>
    <div style="padding-top: 8em;">
        <section class="intro clearfix header_service">
            <div class="container" style="max-width:65em; min-height:20em">
                <div class="row">
                <div class="overlay-green"></div>
                    <v-col class="col-md-8 intro-info text-left align-self-center" md="8" style="z-index: 1;">
                        <h1 class="text-uppercase bold text-white mb-4 ls-1 w-75">Pembuatan dan Perubahan Akta</h1>
                        <p class="mb-5 text-white h5">Legalpedia siap melayani Jasa Pembuatan dan Perubahan Akta sesuai kebutuhan Anda. <br> <br>
                            Legalpedia melayani pembuatan dan perubahan akta badan usaha yang digunakan untuk memenuhi kebutuhan bisnis anda.
                        </p>
                    </v-col>
                    <v-col class="col-md-4" md="4" style="z-index: 1;">
                        <div class="card text-darkgreen">
                            <div class="card-header pt-5 pb-5 border-0">
                                <span>Mulai dari</span>
                                <p class="mt-2 text-center mb-0 pb-0"><s>5.500.000</s></p>
                                <h3 class="text-center mb-0">
                                    IDR 4.000.000
                                </h3>
                            </div>
                            <div class="card-body">
                                <v-btn block small color="primary" class="p-4">Dapatkan Sekarang</v-btn>
                            </div>
                        </div>
                    </v-col>
                </div>
            </div>
        </section>
        <section>
            <v-container class="mt-2">
                <v-row>
                    <v-col cols="12" class="mb-0 mt-0">
                        <v-breadcrumbs :items="breadcumbs" class="mb-1 pb-1 breadcrumb">
                            <template v-slot:item="{ item }">
                                <v-breadcrumbs-item
                                    :to="item.href"
                                    class="text-subtitle-2 crumb-item "
                                    :disabled="item.disabled"
                                    exact
                                >
                                    {{ item.text }}
                                </v-breadcrumbs-item>
                            </template>
                        </v-breadcrumbs>
                    </v-col>
                </v-row>
            </v-container>
        </section>
        <section class="mt-8 mb-8" id="start">
            <v-container class="container">
                <v-row align="center" justify="center">
                    <div class="col-md-3 mb-5" v-for="(detail, index) in services" :key="index">
                        <div class="card h-100">
                            <img :src="detail.image" class="mt-3 mb-3 w-50 center" style="display: block; margin: auto; height: 160px;" v-if="detail.image">
                            <img style="display: block; margin: auto; height: 160px;" src="@/assets/akta.png" class="mt-3 mb-3 w-50 center" v-else>
                            <div class="card-body" style="height: 200px;">
                                <h5 class="font-open-sans mb-0">{{detail.title}}</h5>
                                <p class="font-size-14 mb-0">{{detail.description}}</p>
                            </div>
                            <div class="card-footer bg-white">
                                <span class="small"><s>{{detail.discount_price }}</s></span>
                                <p class="bold mb-0 mt-0">IDR {{detail.price }}</p>
                                <a :href="detail ? detail.url_link_wa : 'https://api.whatsapp.com/send?phone=+6282120938886&text=Hi%20LegalPedia,%20Saya%20ingin%20konsultasi%20.%20Mohon%20Dibantu'" target="_blank" >
                                    <v-btn class="" color="success">
                                        Pesan Sekarang
                                    </v-btn>
                                </a>
                                
                            </div>
                        </div>
                    </div>                    
                </v-row>
            </v-container>
        </section>
        <contact-us></contact-us>
    </div>
</template>
<script>
import { mapState } from 'vuex'

import ContactUs from "@/components/layout_front/ContactUs.vue"
import HeaderService from "@/components/layout_front/HeaderService.vue"
import {service_item_api} from "@/backend-api/service_item_api"

export default {
    components: {
        ContactUs, HeaderService
    },
    data: () => ({
        data : '',
        panel:[0,1,2,3],
        breadcumbs: [
            {
                text: 'Home',
                disabled: false,
                href: '/',
            },
            {
                text: 'Pembuatan dan Perubahan Akta',
                disabled: true,
            }
        ],
        services: []
    }),
    methods:{
        async fetchData(){
            var respData = await service_item_api.front(`?service_id=7`, null, false, false, false)
            if (respData.status === 200) {
                this.services = respData.data.data
            } 
        },
    },
    async mounted(){
        await this.fetchData()
    },
    watch: {
    }
}
</script>

<style scoped>

</style>